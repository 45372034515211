import React, {useEffect, useRef, useState} from 'react';
import "./MainPage.css"
import {Button, FormControl, Image, InputGroup} from "react-bootstrap";
import UserList from "../components/UsersList/UserList";
import Chat from "../components/ChatContainer/Chat";
import ProfileModal from "../components/ViewProfileModal/ProfileModal";
import {API} from "../utils/api";
import {useNavigate} from "react-router-dom";
import { io } from "socket.io-client";
import data from "bootstrap/js/src/dom/data";
import MyMessageItem from "../components/ChatContainer/MessagesItems/MyMessageItem";


const MainPage = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [messages, setMessages] = useState([])
    const messageInput = useRef()
    const [users, setUsers] = useState([])
    const [userProfile, setProfile] = useState({
        username:"username",
        avatar:""})
    const navigate = useNavigate()
    const inputFile = useRef(null)
    let socket = useRef()

    useEffect(()=>{
        if (!localStorage.getItem("token")){
            navigate('/login')
        }
        console.log("Mounted")

        API.getProfileInfo().then((res)=>{
            setProfile({
            username: res.data.nickname,
            avatar: res.data.avatar})
            localStorage.setItem("username", res.data.nickname)
            localStorage.setItem("avatar", res.data.avatar)
        }).catch(()=>setProfile({
            username: localStorage.getItem("username"),
            avatar: localStorage.getItem("avatar")
        }))

        socket.current = io("https://gigachat-api.herokuapp.com/",
            {'transports': ['polling'], extraHeaders:{
                    Authorization: localStorage.getItem("token")
                }},
        )
        socket.current.on("receive_message", onMessage)
        socket.current.on("receive_users", onUsersRequested)
        socket.current.on("receive_messages", onMessagesRequested)
        socket.current.on("receive_events", onEvents)

        socket.current.emit("request_messages")
        socket.current.emit("request_users")


        inputFile.current.addEventListener("change", sendImage, false);
    }, [])


    const sendMessageCommand = () =>{
        if (messageInput.current.value === "")
            return
        const message = messageInput.current.value
        console.log(message)
        socket.current.emit("send_message", message)
        setMessages(prev=>[...prev, {type:"me", content:message}])
        messageInput.current.value = ""
    }

    const onMessage = (data) =>{
        console.log(data)
        setMessages(prev=>[...prev, data])
    }

    const onMessagesRequested = (data) =>{
        console.log(data)
        setMessages(data)
    }

    const onUsersRequested = (udata) =>{
        console.log(udata)
        setUsers(udata)
    }

    const onEvents = (data) =>{
        console.log(data)
        if (data.event === "user_connected") {
            setMessages(prev => [...prev, {
                type: "server",
                content: `${data.data.username} connected to chat. Welcome`
            }])
            setUsers(prev =>[...prev, {nickname:data.data.username, avatar:data.data.avatar}])
        }
        else if (data.event === "user_disconnected") {
            setMessages(prev => [...prev, {
                type: "server",
                content: `${data.data.username} disconnected from chat. Goodbye`
            }])
            let copyOfObject = { ...users }
            for(let i = 0; i <= users.length; i++)
                if (users[i].nickname === data.data.username && users[i].avatar===data.data.avatar) {
                    delete copyOfObject[i.toString()]
                    setUsers(prev => [...prev])
                }
        }
        else if (data.event === "message"){
            setMessages(prev => [...prev, {
                type: "server",
                content: data.data.message
            }])
        }
    }

    const onFilePick = () =>{
        inputFile.current.click();
    }

    const sendImage = () =>{
        console.log(`Sent file: ${inputFile.current.files[0].name}`)
        socket.current.emit('send_image', inputFile.current.files[0]);
        var fileReader = new FileReader()
        fileReader.onload = function(evt){
            setMessages(prev=>[...prev, {type:"me", content:evt.target.result, contentType:"image"}])
        }
        fileReader.readAsDataURL(inputFile.current.files[0]);
    }

    return (
        <div className="container">
            <input type='file' id='file' ref={inputFile} style={{display: 'none'}}/>
            <div className="header">
                <Button onClick={()=>navigate("/")}>Home</Button>
            </div>

            <div className="user-list">
                <UserList users={users}/>
            </div>
            <div className="chat">
                <Chat messages={messages}/>
            </div>
            <span style={{ width:200}} className="profile-quick-info">
                <Button onClick={() => setModalShow(true)} style={{width:200, overflow:"clip" ,background:"rgba(0, 0, 0, 0.1)", padding:0, maxHeight:37}} variant="outline-primary">
                    {userProfile.avatar!==null ?
                        <Image width="25" height="25" roundedCircle={true}
                               src={API.baseurl + userProfile.avatar}
                               onError={(t)=>t.currentTarget.src="user.png"} />
                        :
                        <></>
                    }
                    <span >{userProfile.username}</span>
                </Button>
            </span>
            <div className="chat-input">
                <InputGroup style={{background:"rgba(0, 0, 0, 0.1)"}} className="mb-3">
                    <Button onClick={onFilePick} style={{padding:"0 10px 3px 10px"}} variant="outline-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                             className="bi bi-paperclip" viewBox="0 0 16 16">
                            <path
                                d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                        </svg>
                    </Button>
                    <FormControl
                        placeholder="Send Message"
                        ref={messageInput}
                        style={{background:"transparent", color:"white", borderColor:"#0d6efd"}}
                        onKeyDown={(e) => e.key === "Enter" ? sendMessageCommand() : ()=>{}}
                    />
                    <Button style={{padding:"0 10px 3px 10px"}} onClick={sendMessageCommand} variant="outline-primary">
                        <svg width="20px" height="20px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <title>send_28</title>
                            <g id="Page-2" stroke="none" fill="none">
                                <g id="send_28">
                                    <rect id="Rectangle-76" x="0" y="0" width="28" height="28"></rect>
                                    <path d="M6.93444324,18.122659 C6.17111854,20.1666508 5.69152938,21.602505 5.49567576,22.4302216 C4.88085257,25.0285873 4.43380151,25.6138572 6.72532205,24.378996 C9.01684259,23.1441348 20.1104179,17.0270573 22.5854748,15.6676078 C25.8118169,13.8955074 25.8549307,14.0339578 22.412382,12.1524069 C19.7904299,10.7193594 8.82689354,4.76182814 6.72532209,3.59206955 C4.62375064,2.42231096 4.88085262,2.94247803 5.49567577,5.54084394 C5.69406787,6.37928872 6.18062257,7.82796582 6.95533987,9.88687522 L6.95534708,9.88687251 C7.49471324,11.3203064 8.74065273,12.3701428 10.2447972,12.6585836 L16.6774552,13.8921361 C16.7376285,13.9040504 16.7766526,13.9620012 16.7646179,14.0215728 C16.755821,14.0651175 16.7214398,14.0991549 16.6774552,14.1078639 L10.2302867,15.3402412 C8.72124232,15.6286942 7.47193845,16.6833818 6.93444324,18.122659 Z" id="Mask" fill="currentColor"></path>
                                </g>
                            </g>
                        </svg>

                    </Button>
                </InputGroup>
            </div>

            <ProfileModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
};

export default MainPage;
