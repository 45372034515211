import React, {useEffect, useState, useRef} from 'react';
import {Form, FormControl, InputGroup, Modal, Stack} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {API} from "../../utils/api";
import data from "bootstrap/js/src/dom/data";
import {useNavigate} from "react-router-dom";

const ProfileModal = props => {
    const [name, setName] = useState("")
    const navigate = useNavigate()
    const fileInput = useRef()
    useEffect(()=>{
        API.getProfileInfo().then((data)=>{
            setName(data.data.nickname)
        })
    },[])

    const logOut = ()=>{
        localStorage.setItem("token", "")
        localStorage.setItem("nickname", "")
        localStorage.setItem("avatar", "")
        navigate("/")
        props.onHide()
    }

    const apply = () =>{
        API.sendProfileInfo(name, fileInput.current.files[0]).then((res)=>{
            localStorage.setItem("nickname", res.data.nickname)
            localStorage.setItem("avatar", res.data.avatar)
            window.location.reload();
            props.onHide()
        })
    }

    return (
        <Modal {...props}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h1 style={{color:"black"}}>Your Profile</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><h3 style={{color:"black"}}>Никнейм</h3></Form.Label>
                        <Form.Control type="login" value={name} onChange={(e)=>setName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><h3 style={{color:"black"}}>Новое Фото профиля</h3></Form.Label>
                        <InputGroup>
                            <FormControl ref={fileInput} type="file"/>
                        </InputGroup>
                    </Form.Group>
                </Form>
                <Stack>
                <Button onClick={apply}>Подтвердить</Button>
                <Button onClick={logOut} style={{marginTop:5}} variant="outline-danger">Logout</Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
};

ProfileModal.propTypes = {

};

export default ProfileModal;
