import React, {useEffect, useRef, useState} from 'react';
import {
    Badge,
    Container,
    Form,
    FormCheck,
    FormControl,
    InputGroup,
    NavItem,
    NavLink,
    Spinner,
    Stack
} from "react-bootstrap";
import {useFetching} from "../hooks/useFetching";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {API} from "../utils/api"
import axios from "axios";

function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

const LoginPage = () => {
    let navigate = useNavigate()
    const passInput = useRef()
    const [emailText, setEmail] = useState()
    const [passText, setPass] = useState()
    let [validationError, setError] = useState()

    const [login, logging] = useFetching( () => {
        API.sendLogin(emailText, passText).then((res)=>{
            if (res.data.token) {
                navigate("/chat")
                localStorage.setItem("token", res.data.token)
                localStorage.setItem("username", res.data.nickname)
                localStorage.setItem("avatar", res.data.avatar)
                API.setHeader("Authorization", "Bearer " + res.data.token)
            }
        }).catch(()=>{
            setError("Проверьте данные")
        })

    } )

    useEffect(()=>{
        localStorage.setItem("token", "")
        localStorage.setItem("username", "")
        localStorage.setItem("avatar", "")
        API.setHeader("Authorization", "")
    }, [])

    return (
        <Stack style={{width:"400px"}}>
            <h3 style={{textAlign:"center"}}>Вход</h3>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Email или логин</Form.Label>
                    <Form.Control type="email" placeholder="Email" value={emailText} onChange={(e) => setEmail(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Пароль</Form.Label>
                    <InputGroup>
                        <FormControl ref={passInput} type="password" id="inlineFormInputGroupUsername" placeholder="Пароль"
                                     value={passText} onChange={(e) => setPass(e.target.value)}
                        />
                        <Button style={{padding:"0px 13px 2px 13px"}} onClick={()=> {
                            if (passInput.current.type==="text")
                                passInput.current.type="password"
                            else
                                passInput.current.type="text"
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-eye-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                <path
                                    d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                            </svg>
                        </Button>
                    </InputGroup>
                </Form.Group>
            </Form>
            {validationError ?
                <h5 style={{color:"red", textAlign:"center"}}>{validationError}</h5>
                :
                <div></div>
            }
            <Button onClick={login} className="btn btn-primary">
                {logging ?
                    <Spinner as="span"
                             animation="border"
                             size="sm"
                             role="status"
                             aria-hidden="true"/>
                    :
                    "Вход"
                }
            </Button>

            <Button style={{marginTop:5}} type="button" variant="danger" onClick={()=> navigate("/")}>Отмена</Button>



        </Stack >
    );
};

export default LoginPage;
