import React from 'react';
import classes from "./OthersMessageItem.module.css";
import {Image} from "react-bootstrap";
import {API} from "../../../utils/api";
import {MemoImage} from "../../MemoImage";

const OthersMessageItem = ({message}) => {
    return (
        <div className={classes.container}>
            {message.avatar===null || message.avatar==="" ?
                <div style={{width:50}}/>
                :
                <MemoImage fallback="user.png"
                           src={API.baseurl + message.avatar}
                           width={40} height={40}
                />
            }
            <div className={classes.messageContent}>
                <h6>{message.author}</h6>
                {message.contentType === "image" ?  <Image style={{maxHeight:300, maxWidth:300}} src={API.baseurl + message.content}/> : <p>{message.content}</p>}
                <p style={{textAlign:"right", fontSize:9}}>{message.timestamp}</p>
            </div>
        </div>
    );
};

export default OthersMessageItem;
