import React from 'react';
import classes from "./UserList.module.css";
import {ListGroup} from "react-bootstrap";
import {API} from "../../utils/api";
import {v4} from "uuid";
import {MemoImage} from "../MemoImage";

const UserList = ({users}) => {
    return (
        <div className={classes.container}>
            <h3 style={{color:'white'}} className={classes.Header}>Users:</h3>
            <ListGroup variant="flush" as="ol" numbered>
                {users.map((user)=>{
                    return <ListGroup.Item key={v4()} style={{background:"transparent", maxHeight:38 ,padding:0, color:"white", wordWrap:"normal"}} action >
                    <span>
                        <MemoImage fallback="user.png"
                                   src={API.baseurl + user.avatar}
                                   width={30} height={30}
                        />
                        {user.nickname}
                    </span>

                    </ListGroup.Item>
                })}
            </ListGroup>
        </div>
    );
};

export default UserList;
