import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {useNavigate } from "react-router-dom";
import {Alert, Image, NavLink} from "react-bootstrap";
import Chat from "../components/ChatContainer/Chat";

const HomePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <h1 style={{textAlign:"center", fontSize:55}} id="header">GIGACHAT SHIZOPRENIA</h1>

        <Container className="p-3" style={{alignItems:"center", display:"flex", flexDirection:"column", background:"rgba(40,40,141,0.5)"}}>
            <div style={{display:"flex"}}>
                    <h1>
                        Fast,<br/>
                        Impressive,<br/>
                        Worldwide<br/>
                        App<br/>
                    </h1>
                    <img style={{display:"inline"}} width="500" height="200" align="right" src="chad.png" alt="chad png lol"/>
            </div>
            <h3 style={{textAlign:"left", width:700}}>to connect people through around the world</h3>
            <p style={{margin:10}}>

                <span style={{margin:10}}>
                <Button onClick={() => navigate("/login")}>Login</Button>
                </span>
                or
                <span style={{margin:10}}>

                <Button onClick={() => navigate("/reg")}>Register</Button>
                </span></p>
        </Container>
        </div>
    );
};

export default HomePage;
