import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import HomePage from "../pages/HomePage";
import ErrorPage from "../pages/ErrorPage";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import MainPage from "../pages/MainPage";
import {API} from "../utils/api";

const AppRouter = () => {
    const navigate = useNavigate()

    useEffect(()=>{
        if (localStorage.getItem("token")) {
            API.setHeader("Authorization", "Bearer " + localStorage.getItem("token"))
            navigate("/chat")
        }
    }, [])

    return (
        <Routes path="/">
            <Route index element={<HomePage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/reg" element={<RegisterPage/>}/>
            <Route path="/chat" element={<MainPage/>}/>
            <Route path='*' element={<ErrorPage/>}/>
        </Routes>
    );
};

export default AppRouter;
