import React from 'react';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {useNavigate } from "react-router-dom";

const ErrorPage = () => {
    let navigate = useNavigate()
    return (
        <Container className="p-3" style={{display:"block"}}>
            <h1>Сорри но page которую ты finding not found😔</h1>
            <img src="pudge.gif" alt="padge gif lol"/>
            <img src="pudge.gif" style={{transform: "scaleX(-1)"}} alt="padge gif lol"/>
            <hr/>
            <Button onClick={() => navigate("/")}>Вернуться на парашу</Button>
        </Container>
    );
};

export default ErrorPage;
