import axios from "axios";
export class API {
    static baseurl = "https://gigachat-api.herokuapp.com/";

    static sendLogin = (email, password) => {
        return axios.post(this.baseurl + "auth/login", {
            "email" : email,
            "password" : password
        })
    }


    static setHeader = (header, value) => {
        axios.defaults.headers.post[header] = value
        axios.defaults.headers.get[header] = value
    }


    static sendRegData = ({email, nickname, password, file}) =>{
        let formData = new FormData();
        formData.append("nickname", nickname)
        formData.append("email", email)
        formData.append("password", password)

        if (file != null)
            console.log(file)
            formData.append("avatar", file, "filename.jpg");

        return axios.post(this.baseurl + "auth/registration",  formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static getProfileInfo = () =>{
        return axios.get(API.baseurl + "users/profile")
    }

    static sendProfileInfo = (nickname, avatar) =>{
        let data = new FormData()
        data.append("nickname", nickname)
        if (avatar != null)
            data.append("avatar", avatar, "filename.jpg")
        else
            data.append("avatar", "")

        return axios.post(API.baseurl + "users/profile", data)
    }
}
