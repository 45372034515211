import {React, useRef, useState} from 'react';
import {Container, Form, FormControl, InputGroup, OverlayTrigger, Spinner, Stack, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import {API} from "../utils/api";
import {InfoCircle} from "react-bootstrap-icons";

const RegisterPage = () => {
    let navigate = useNavigate()
    const [login, logging] = useFetching( () => {
        if (registerData.password !== registerData.passwordRepeat)
        {
            setError("Пароли не равны")
            return
        }
        let file = null
        if (fileInput.current.files[0])
            file = fileInput.current.files[0]
        API.sendRegData({...registerData, file:file}).then((res)=>{
            console.log(res.data)
            if (res.data.token) {
                navigate("/chat")
                localStorage.setItem("token", res.data.token)
                localStorage.setItem("username", res.data.nickname)
                localStorage.setItem("avatar", res.data.avatar)
                API.setHeader("Authorization", "Bearer " + res.data.token)
            }
        }).catch(()=> setError("Проверьте данные и повторите попытку"))

    })
    const passInput = useRef()
    const [registerData, setRegData] = useState({
        nickname:"",
        email:"",
        password:"",
        passwordRepeat:""
    })

    const fileInput = useRef()

    const [validationError, setError] = useState("")

    return (
        <Stack style={{width:"400px", height:"100%"}}>
            <h2 style={{textAlign:"center"}}>Регистрация</h2>
            <Form>
                <Form.Group className="mb-3" >
                    <Form.Label>Никнейм</Form.Label>
                    <Form.Control type="text" value={registerData.nickname} onChange={(e)=>setRegData({...registerData, nickname: e.target.value})}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={registerData.email} onChange={(e)=>setRegData({...registerData, email: e.target.value})}/>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Минимальная длина 4<br/>Максимальная:16</Tooltip>}
                    >
                        <span>
                        <Form.Label>Пароль </Form.Label>
                            <InfoCircle style={{marginLeft:4}}/>
                        </span>
                    </OverlayTrigger>
                    <InputGroup>
                        <FormControl ref={passInput} type="password" value={registerData.password} onChange={(e)=>setRegData({...registerData, password: e.target.value})} />
                        <FormControl type="password"  placeholder="Confirm" value={registerData.passwordRepeat} onChange={(e)=>setRegData({...registerData, passwordRepeat: e.target.value})}/>
                        <Button style={{padding:"0px 13px 2px 13px"}} onClick={()=> {
                            if (passInput.current.type==="text")
                                passInput.current.type="password"
                            else
                                passInput.current.type="text"
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-eye-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                <path
                                    d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                            </svg>
                        </Button>
                    </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Фото профиля (Необязательно)</Form.Label>
                    <InputGroup>
                        <FormControl ref={fileInput} type="file" />
                    </InputGroup>
                </Form.Group>


            </Form>

            {validationError ?
                <h4 style={{color:"red", textAlign:"center"}}>{validationError}</h4>
                :
                <div></div>
            }
            <Button  onClick={login} className="btn btn-primary">
                {logging ?
                    <Spinner as="span"
                             animation="border"
                             size="sm"
                             role="status"
                             aria-hidden="true"/>
                    :
                    "Регистрация"
                }
            </Button>

            <Button style={{marginTop:5}} type="button" variant="danger" onClick={()=> navigate("/")}>Отмена</Button>


        </Stack >
    );
};

export default RegisterPage;
