import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {isMobile} from 'react-device-detect';
import {API} from "./utils/api";


const renderContent = () => {
    if (isMobile) {
        return <h1> This content is available only on pc👌😜👌</h1>
    }
    return <App />
}

if (localStorage.getItem("token"))
    API.setHeader("Authorization", "Bearer " + localStorage.getItem("token"))
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(renderContent());
