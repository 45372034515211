import React from 'react';
import classes from "./MyMessages.module.css";
import {Image} from "react-bootstrap";
import {API} from "../../../utils/api";

const MyMessageItem = ({children, type}) => {
    return (
        <div className={classes.container}>
            <div className={classes.messageContent}>
                {type === "image" ?  <Image style={{maxHeight:300, maxWidth:300}} src={children}/> : <p style={{color:"white"}}>{children}</p>}
            </div>
        </div>
    );
};

export default MyMessageItem;
