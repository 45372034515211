import React, {useEffect, useRef, useState} from 'react';
import classes from "./Chat.module.css";
import MyMessageItem from "./MessagesItems/MyMessageItem";
import OthersMessageItem from "./MessagesItems/OthersMessageItem";
import ServerMessagesItem from "./MessagesItems/ServerMessagesItem";
import {v4} from "uuid";

const Chat = ({messages}) => {
    const container = useRef(null)
    return (
        <div  className={classes.container}>
            <h3 id="header" style={{textAlign:"center", color:'white'}}>GIGACHAT SHIZOPRENIA</h3>
            <hr style={{margin:"0 15px 0 15px"}}/>
            <div ref={container} className={classes.messages}>
                {messages.map(function (mess){
                    if(container.current.scrollHeight - container.current.scrollTop < 500)
                        setTimeout(()=>container.current.scrollTop = container.current.scrollHeight, 100)
                    if (mess.type==="me")
                        return <MyMessageItem type={mess.contentType} key={v4()}>{mess.content}</MyMessageItem>
                    if (mess.type==="server")
                        return <ServerMessagesItem key={v4()}>{mess.content}</ServerMessagesItem>
                    return <OthersMessageItem key={v4()} message={mess}/>
                    }
                )}
            </div>
        </div>
    );
};

export default Chat;
