import React from 'react';
import classes from "./ServerMessagesItem.module.css";

const ServerMessagesItem = ({children}) => {
    return (
        <div className={classes.container}>
            <div className={classes.messageContent}>
                <p style={{color:"white"}}>{children}</p>
            </div>
        </div>
    );
};

export default ServerMessagesItem;
